import { Container, Carousel, Row, Col, Button } from "react-bootstrap";
import { useState, useLayoutEffect, useEffect } from "react";

import Link from "next/link";
import styles from "../../styles/Home.module.css";

const TopCategories = (props) => {
  const { category, index } = props;

  const trackClick = (event) => {
    window.analytics.track(`homepage_top_categories_clicked` , event)
  };
  
  
  return (
    <div
    className="mds-shadow-hover border mds-border-primary-light mds-rounded-lg mb-3 p-2" 
      // className="mt-3 p-1"
      style={{ backgroundColor:"#fff7ea" }}
    >
      <Link href="/category/[id]" as={`/category/${category.slug}`}>
        <a
        id={"topCategoryLink" + index}
        className={styles.card} onClick={() => trackClick(category)}>
          <Row>
            <Col
            lg={2}
              md={2}
              xs={2}
              sm={2}
              style={{
                paddingLeft: "19px",
                paddingRight: "0px",
                paddingTop: "5px",
                paddingBottom: "8px",
              }}
            >
              <div>
                <img src={category.iconUrl} width="40" height="40"></img>
              </div>
            </Col>
            <Col md={10} xs={10} sm={10}  lg={10} style={{paddingLeft:props.width > 800 ? "5px": "15px"}}>
              <Row>
                <Col md={12}>
                  <h4 className="mt-1" >{category.name}</h4>
                </Col>

                <Col md={12}>
                  {category.shortDescription && category.shortDescription.length > 50 ? (
                    <p className="text-muted">
                      {category.shortDescription.slice(0, 50)}...
                    </p>
                  ) : (
                    <p className="text-muted">{category.shortDescription}</p>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </a>
      </Link>
    </div>
  );
};

export default TopCategories;
