import { Container, Carousel, Row, Col, Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Link from "next/link";
import styles from "../../styles/Home.module.css";
import { useState, useLayoutEffect, useEffect } from "react";
import ImageCompanyLogo from "../ImageCompanyLogo";
import { RiSendPlane2Fill } from "react-icons/ri";

const array = [0, 1, 2];

const TopStacks = (props) => {
  const { stack, index } = props;

  const renderCompanyName = (props) => <Tooltip>{props}</Tooltip>;
  const renderVerified = (props) => <Tooltip {...props}>Verified</Tooltip>;

  const trackClick = (event) => {
    window.analytics.track(`homepage_top_stacks_clicked` , event)
  };

  return (
    <div
      className="mds-shadow-hover border mds-border-primary-light mds-rounded-lg mb-3"
      style={{
        backgroundColor: "#fff7ea",
      }}
    >
      <Link href="/stacks/[id]" as={`/stacks/${stack.slug}`}>
        <a
        id={"topStackLink" + index}
        className={styles.card} onClick={() => trackClick(stack)}>
          <Row>
            <Col
              md={1}
              xs={1}
              sm={2}
              style={{
                paddingLeft: "19px",
                paddingTop: "5px",
                paddingRight: "25px",
                paddingBottom: "8px",
              }}
            >
              <ImageCompanyLogo company={stack}
                width={50} height={50}
                className="rounded-circle"
                />
            </Col>
            <Col md={10} xs={10}>
              <Row style={{ paddingLeft: props.width > 575 ? "15px" : "25px" }}>
                <Col md={12}>
                  {stack.verified === true ? (
                    <h4 className="mt-1 ml-1 text-capitalize">
                      {stack.companyName}{" "}
                      <span className="m-1">
                        <OverlayTrigger
                          trigger="hover"
                          placement="top"
                          rootClose
                          overlay={renderVerified}
                        >
                          <img
                            src="Illustrations/verified.png"
                            width="20"
                            height="20"
                          />
                        </OverlayTrigger>
                      </span>{" "}
                    </h4>
                  ) : (
                    <h4 className="mt-1 ml-1 text-capitalize">{stack.companyName}</h4>
                  )}
                </Col>
                <p className="text-muted m-0" style={{ margin: "0px" }}>
                      {stack.description.slice(0, 100)}{stack.description.length > 100 && "..."}
                    </p>
                <div className="py-2">
                  <div className="d-flex justify-content-start align-items-center gap-3">
                    {stack.vendors
                      .slice(0, props.width > 1000 ? 5 : 3)
                      .map((company) => {

                        return (
                          <OverlayTrigger
                            placement="top"
                            key={company.id}
                            overlay={renderCompanyName(company.companyName)}
                          >
                            <span>
                              <Link
                                href="/company/[id]"
                                as={`/company/${company.slug}`}
                                scroll={false}
                              >
                                <a>
                                  <span className="d-flex align-items-center">
                                  <ImageCompanyLogo company={company}
                                  width={32}
                                  height={32}
                                  className="rounded-circle"
                                  />
                                  </span>
                                </a>
                              </Link>
                            </span>
                          </OverlayTrigger>
                        );
                      })}

                    {stack.vendors.length > 3 &&
                    <div
                    className="d-flex align-items-center justify-content-center"
                    style={{
                      border: "0.1px solid #f77855",
                      color: "#f77855",
                      borderRadius: "50%",
                      height: "32px",
                      width: "32px"
                    }}
                  >
                    <small>+{stack.vendors.length - 3}</small>
                  </div>
                    }
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </a>
      </Link>
    </div>
  );
};

export default TopStacks;
