import styles from '../styles/Home.module.css';
import {Container, Row, Col, Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useState, useLayoutEffect} from 'react';
import Link from 'next/link';
import {useUser} from '@auth0/nextjs-auth0';
import axios from 'axios';
import TopCompanies from '../components/LandingPageComponents/TopCompanies';
import TopCategories from '../components/LandingPageComponents/TopCategories';
import TopStacks from '../components/LandingPageComponents/TopStacks';
import Form from 'react-bootstrap/Form';
import HeadComponent from '../components/HeadComponent';
import {Spinner} from 'react-bootstrap';
import validator from 'validator';
import Image from 'next/image';
import {callBackendApi} from '@/utils/common';
import mdsBannerImage from '../assets/Illustrations/mdsBannerImage23.svg';
import ImageCompanyLogo from '@/components/ImageCompanyLogo';
import {BsDot} from 'react-icons/bs';
import moment from 'moment';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const Home = props => {
  const {rocketShips, juryList, featuredJournals, podcast} = props;
  const [width, height] = useWindowSize();
  const [subscribeButtonLoading, setsubscribeButtonLoading] = useState(false);
  const {user, error, isLoading} = useUser();
  const [userEmail, setuserEmail] = useState('');
  const [emailError, setEmailError] = useState({status: true, message: ''});
  const [ghostSubscription, setghostSubscription] = useState(false);

  const latestRocketShip =
    Array.isArray(rocketShips) && rocketShips.length > 0
      ? rocketShips[rocketShips.length - 1]
      : null;

  const handleEmail = e => {
    e.preventDefault();
    setuserEmail(e.target.value);
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError({status: false, message: 'Valid Email!'});
    } else {
      setEmailError({status: true, message: 'Enter valid Email!'});
    }
  };

  const trackClick = event => {
    window.analytics.track(`homepage.${event}_clicked`, user);
  };

  const handleSubscribeToNewsletter = async () => {
    try {
      if (userEmail !== '' && userEmail != 'undefined') {
        setsubscribeButtonLoading(true);
        setghostSubscription(false);
        // window.analytics.track(
        //   `homepage.Subscribe_To_Newletter_clicked`,
        //   userEmail
        // );
        await callBackendApi({
          method: 'post',
          url: '/newsletter',
          data: {email: userEmail},
        });
        setghostSubscription(true);
        setuserEmail('');
        setsubscribeButtonLoading(false);
      }
    } catch (error) {
      setEmailError({status: true, message: 'User already subscribed'});
      setsubscribeButtonLoading(false);
    }
  };

  return (
    <>
      <div
        className={styles.container}
        style={{
          // backgroundColor: props.nav || props.loading ? "#dcd5ca" : "#FEEBDC",
          backgroundColor: '#FEEBDC',
          // opacity: props.nav || props.loading ? "0.4" : "1",
          paddingBottom: '198px',
        }}
      >
        <HeadComponent
          title={'Modern Data Stack - Everything that you need to know !'}
          url={'https://www.moderndatastack.xyz'}
          description={
            'Resources and tools to help you build and operate a Modern Data Stack'
          }
        />

        <Container className="pb-5">
          {/* <iframe  src={`${process.env.NEXT_PUBLIC_BASE_URL}iframe/Instagram`} style={{width:"100%",height:"500px"}} /> */}

          <Row className="justify-content-start mt-5">
            <Col md={6} className="justify-content-start">
              <h1>The Modern Data Stack Repository.</h1>
            </Col>
            <Col md={2} className="justify-content-start"></Col>
            <Col>
              <Row style={{marginLeft: props.width > 575 ? '15px' : null}}>
                <Col md={12} className="d-flex justify-content-end">
                  <p style={{fontSize: '20px'}}>
                    Everything that you need to know about building and operating a Modern
                    Data Stack.
                  </p>
                </Col>
                <Col md={12}>
                  <Link href="/stacks">
                    <a className={styles.card}>
                      <Button
                        onClick={() => trackClick('Explore_Data_stack')}
                        style={{
                          minWidth: '200px',
                          minHeight: '40px',
                          backgroundColor: '#f66640',
                          color: 'white',
                          border: '1px solid #f66640',
                          borderRadius: '5px',
                        }}
                      >
                        Explore data stacks
                      </Button>
                    </a>
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {/* ========== rocketship banner ============= */}
      <div
        className="container"
        style={{
          marginTop: '-148px',
        }}
      >
        <div
          className="p-3 p-lg-4"
          style={{borderRadius: '16px', backgroundColor: '#222'}}
        >
          <div className="d-lg-none justify-content-center d-flex">
            <Image src={mdsBannerImage} width={250} />
          </div>
          <div className="d-flex justify-content-between align-items-center mb-3 px-3">
            <div className="">
              <h2 className="text-white mds-fw-lg">
                <span className="mds-text-primary">MDS</span> Rocketship Awards’23
              </h2>
              <p className="m-0 mds-text-page-bg mds-fw-sm">
                An effort to bring the data community together to recognise & celebrate
                the most impactful data companies in the world.
              </p>
            </div>
            <div className="d-none d-lg-inline">
              <Image src={mdsBannerImage} width={250} />
            </div>
          </div>
          <p className="mds-text-page-bg mds-fw-sm mds-fs-5 px-3">
            Rocketship of the day
          </p>

          <Link href="/awards">
            <a>
              <div
                className="card border-0"
                style={{backgroundColor: '#111111', borderRadius: '8px'}}
              >
                <div className="card-body text-white">
                  <div className="d-flex flex-column flex-lg-row justify-content-start gap-2 align-items-lg-center">
                    <div className="d-flex align-items-center justify-content-start gap-3">
                      <ImageCompanyLogo
                        className="rounded-circle"
                        company={latestRocketShip.vendor}
                      />
                      <h4 className="d-lg-none">{latestRocketShip.vendor.companyName}</h4>
                    </div>

                    <div>
                      <div className="order-0 order-lg-1 align-self-stretch align-self-lg-center">
                        <div className="d-flex flex-lg-column flex-column-reverse ">
                          <div className="d-flex gap-2 flex-row">
                            <h4 className="d-none d-lg-block">
                              {latestRocketShip.vendor.companyName}
                            </h4>
                          </div>
                          <div className="d-lg-none">
                            <hr style={{border: '1px solid #3A3A3A'}} />
                          </div>
                          <div className="d-flex flex-column flex-lg-row align-items-lg-center gap-2 gap-lg-1">
                            <div className="d-flex align-items-center gap-1">
                              <span className="text-nowrap">
                                {latestRocketShip.category.name}
                              </span>{' '}
                              <BsDot />
                              <span className="text-nowrap">
                                {moment(latestRocketShip.date).format('MMMM DD')}
                              </span>
                              <span className="d-none d-lg-inline">
                                <BsDot />
                              </span>
                            </div>

                            <div className="d-flex align-items-start align-items-lg-center">
                              <div className="text-nowrap">Jury :</div>
                              <div className="d-none ms-2  d-lg-block">
                                <div className="d-flex gap-1 align-items-center">
                                  {juryList.length > 0 &&
                                    juryList.map((jury, index) => {
                                      return (
                                        <OverlayTrigger
                                          key={index}
                                          placement="top"
                                          overlay={props => (
                                            <Tooltip {...props}>
                                              <span className="">
                                                {jury.name}, {jury.designation}@
                                                {jury.companyName}
                                              </span>
                                            </Tooltip>
                                          )}
                                        >
                                          <span>
                                            <Image
                                              className="rounded-circle"
                                              src={jury.imageUrl}
                                              width={24}
                                              height={24}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      );
                                    })}
                                  {/* {juryList.length > 10 && (
                      <span>
                        <Link href="/awards#jury-section">
                          <a
                            style={{width: '40px', height: '40px'}}
                            className="border text-white rounded-circle d-flex justify-content-center align-items-center"
                          >
                            <small> +{juryList.length - 10}</small>
                          </a>
                        </Link>
                      </span>
                    )} */}
                                </div>
                              </div>
                              <div className="d-lg-none ms-2 flex-fill">
                                <div className="d-flex flex-column gap-2 align-items-stretch ">
                                  <div className="d-flex justify-content-start gap-3">
                                    {juryList.length > 0 &&
                                      juryList.slice(0, 6).map((jury, index) => {
                                        return (
                                          <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={props => (
                                              <Tooltip {...props}>{jury.name}</Tooltip>
                                            )}
                                          >
                                            <span>
                                              <Image
                                                className="rounded-circle"
                                                src={jury.imageUrl}
                                                width={24}
                                                height={24}
                                              />
                                            </span>
                                          </OverlayTrigger>
                                        );
                                      })}
                                  </div>
                                  <div className="d-flex align-items-start justify-content-start gap-3">
                                    {juryList.length > 6 &&
                                      juryList.slice(6, 12).map((jury, index) => {
                                        return (
                                          <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={props => (
                                              <Tooltip {...props}>{jury.name}</Tooltip>
                                            )}
                                          >
                                            <span>
                                              <Image
                                                className="rounded-circle"
                                                src={jury.imageUrl}
                                                width={24}
                                                height={24}
                                              />
                                            </span>
                                          </OverlayTrigger>
                                        );
                                      })}
                                    {/* {juryList.length > 12 && (
                        <small
                          style={{width: '40px', height: '40px'}}
                          className="border rounded-circle d-flex justify-content-center align-items-center"
                        >
                          {' '}
                          +{juryList.length - 10}
                        </small>
                      )} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="order-2 align-self-stretch flex-fill d-grid d-lg-flex justify-content-lg-end align-items-lg-center ">
                      <Link href="/awards">
                        <a className="btn text-white border-white mds-btn text-nowrap">
                          View all winners
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </Link>
        </div>
      </div>

      {/* ============ Podcast banner ================== */}

      {/* <div
        className="container"
        style={{
          marginTop: "-148px",
        }}
      >
        <div
          className="overflow-hidden"
          style={{ borderRadius: "16px", backgroundColor: "#222" }}
        >
          <div className="row p-3 p-lg-5 ">
            <div className="col-xl">
              <div className="mb-4">
                <img
                  src="/podcast_logo.svg"
                  height={42}
                  width={97}
                  alt="Modern Data Stack Logo"
                />
              </div>
              <div className="text-capitalize m-0 mb-4 mds-text-page-bg mds-fs-xl mds-fw-lg lh-1">
                The
                <br />
                <span className="mds-text-primary">Modern</span> Data
                <br />
                Show.
                <br />
              </div>

              <Link href={`/podcast/${podcast.slug}`}>
                <a>
                  <PodcastLatestEpiside
                    title={podcast.title}
                    coverPhoto={podcast.coverPhoto}
                    timeDuration={podcast.timeDuration}
                    createdAt={podcast.createdAt}
                    guest1={guest1}
                  />
                </a>
              </Link>
              <div>
                <Link href="/podcast">
                  <a className="btn mds-btn-primary mds-fw-md mds-fs-4 px-5">
                    Listen Now!
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-xl d-flex align-items-center">
              <div
                style={{
                  width: "100%",
                  minHeight: "320px",
                }}
                className="position-relative text-center "
              >
                <Image
                  src={PodcastBanner}
                  layout="responsive"
                  objectFit="cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* ===================================================
     ==================== Amazing people banner ==============  */}

      {/* <div
        className="container"
        style={{
          marginTop: "-148px",
        }}
      >
        <div
          className="overflow-hidden"
          style={{ borderRadius: "16px", backgroundColor: "#222" }}
        >
          <div className="d-flex gap-3 justify-content-between flex-column flex-md-row flex-md-row-reverse">
          <div className="position-relative amazing-people-cover d-none d-lg-block">
              <Image
                src={AmazingPeopleCover}
                layout="fill"
                objectFit="cover"
              />
            </div>
            <div className="position-relative amazing-people-cover d-lg-none">
              <Image
                src={AmazingPeopleCover1}
                layout="fill"
                objectFit="cover"
              />
            </div>
        <div className="p-4">
              <div className="mb-3">
                <span
                  className="mds-text-primary mds-fw-sm mds-font-kongtext px-2 py-1 d-inline-block mds-rounded-sm"
                  style={{ border: "1px dashed #81341f !important" }}
                >Amazing People in Data</span>

              </div>
              <div>
                <h2 className="text-white mds-fw-lg">Stories from Data Practioners</h2>
              </div>
              <p className="text-muted mb-4">Learn how these amazing people have built and are operating a modern data stack</p>
              <div className="d-grid d-md-block">
                <Link href="/people">
                <a className="btn mds-btn-primary px-5 mds-fw-md">Read Stories</a>

                </Link>
              </div>

            </div>
          </div>
        </div>
      </div> */}

      {/* next row */}
      <div
        // className={styles.container}
        style={{
          backgroundColor: props.nav || props.loading ? '#dcd5ca' : '#fff7ea',
          borderRadius: '15px',
          backgroundColor: '#fff7ea',
          // opacity: props.nav || props.loading ? "0.4" : "1",
          marginTop: '30px',
          // margin: width > 1000 ? "60px" : "0px"
        }}
      >
        <Container>
          <Row className="justify-content-between">
            <Col lg={7} md={12} sm={12} className="mt-5 mb-5">
              {/* col-1 row-1 */}
              <Row style={{paddingRight: width < 1000 ? '0px' : '20px'}}>
                <Col md={6} xs={6}>
                  <h4>Top Companies</h4>
                </Col>
                <Col md={6} xs={6} className="d-flex justify-content-end">
                  <Link href="/companies">
                    <a
                      className={styles.card}
                      onClick={() => trackClick('All_Companies_Header')}
                    >
                      <p style={{color: '#f66640', fontSize: '16px'}}>
                        All Companies{' '}
                        <span>
                          <img
                            width="15"
                            height="15"
                            src="/Illustrations/Greater than logo.svg"
                            alt=""
                          />
                        </span>
                      </p>
                    </a>
                  </Link>
                </Col>
                <Col md={12}>
                  <p>
                    Here’s a list of Companies that are shaping the Modern Data Stack.
                  </p>
                </Col>

                <Col md={12}>
                  <Row>
                    {props.companies
                      .filter(company => company.slug !== 'cliff-ai')
                      .map((item, index) => (
                        <Col md={12} key={item.slug}>
                          <TopCompanies index={index} company={item} width={width} />
                        </Col>
                      ))}
                  </Row>
                </Col>
              </Row>
              <Row
                className="mt-5 mb-5"
                style={{
                  paddingRight: width < 1000 ? '0px' : '20px',
                  paddingTop: width < 1000 ? '20px' : '0px',
                  paddingBottom: width < 1000 ? '20px' : '0px',
                  backgroundColor: width < 1000 ? '#FFF1E3' : null,
                }}
              >
                <Col md={6} xs={6}>
                  <h4>Popular Data Stacks</h4>
                </Col>
                <Col md={6} xs={6} className="d-flex justify-content-end">
                  <Link href="/stacks">
                    <a
                      className={styles.card}
                      onClick={() => trackClick('All_Data_Stacks_Header')}
                    >
                      <p style={{color: '#f66640', fontSize: '16px'}}>
                        All Data Stacks{' '}
                        <span>
                          <img
                            width="15"
                            height="15"
                            src="/Illustrations/Greater than logo.svg"
                            alt=""
                          />
                        </span>
                      </p>
                    </a>
                  </Link>
                </Col>
                <Col md={12}>
                  <p>
                    See how some of the most amazing companies in the world are organising
                    their data stack.
                  </p>
                </Col>

                <Col>
                  <Row>
                    {props.stacks.map((item, index) => (
                      <Col md={12} key={item.slug}>
                        <TopStacks index={index} stack={item} width={width} />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>

            {/* second column */}
            <Col
              lg={4}
              md={12}
              sm={12}
              className="mt-5"
              style={{
                backgroundColor: width < 1000 ? '#FFF1E3' : null,
                paddingTop: width < 1000 ? '20px' : '0px',
              }}
            >
              <Row>
                <Col md={12}>
                  <h4>Popular categories</h4>
                </Col>
                <Col md={12}>
                  <p>Key pillars of today’s Modern Data Stack.</p>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col md={12}>
                  <Row>
                    {props.categories.map((item, index) => (
                      <Col md={12} key={item.slug}>
                        <TopCategories
                          index={index}
                          category={item}
                          width={props.width}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              <Row
                className="justify-content-center mb-5 mt-2"
                style={{paddingLeft: width < 1000 ? '0px' : '20px'}}
              >
                <Col md={12} className="d-flex justify-content-center">
                  <Link href="/categories">
                    <a
                      className={styles.card}
                      onClick={() => trackClick('Explore_All_Categories')}
                    >
                      <p style={{color: '#f66640'}}>
                        Explore All Categories{' '}
                        <span>
                          <img
                            width="13"
                            height="13"
                            src="/Illustrations/Greater than logo.svg"
                            alt=""
                          />
                        </span>
                      </p>
                    </a>
                  </Link>
                </Col>

                {width > 1000 && (
                  <Col md={12} className="mt-4 mb-4">
                    <hr
                      className="mt-4 pb-0 mb-0"
                      style={{
                        width: '100%',
                        borderTop: '1px solid #F66640',
                        background: '#F66640',
                        opacity: '0.2',
                      }}
                    />
                  </Col>
                )}
              </Row>

              <div className="mds-bg-primary-muted card border-0 mds-rounded mb-5">
                <div className="card-header bg-transparent border-0 py-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="m-0">Journal</h4>
                    <Link href="/journal">
                      <a className="mds-text-primary">View All</a>
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-2 pb-0">
                  <div className="list-group list-group-flush">
                    {featuredJournals.slice(0, 3).map((item, index) => (
                      <Link key={index} href={`/journal/${item.slug}`}>
                        <a
                          id={`topArticleLink${index}`}
                          className="list-group-item ps-0 mds-border-primary-light bg-transparent pb-3 mb-3"
                        >
                          <div className="d-flex gap-3 justify-content-start">
                            <div>
                              <Image
                                className="mds-rounded overflow-hidden"
                                src={item.journalImageUrl}
                                layout="fixed"
                                width={100}
                                height={60}
                              />
                            </div>
                            <div className="d-flex flex-column justify-content-start gap-1">
                              <span className="fs-6 mds-fw-lg m-0 lh-sm mds-text-dark mds-text-ellipsis-2">
                                {item.title}
                              </span>
                              <p className="text-muted  small m-0 lh-sm mds-text-ellipsis-2">
                                {item.excerpt}
                              </p>
                            </div>
                          </div>
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <Row
                style={{
                  paddingLeft: width < 1000 ? '0px' : '20px',
                  backgroundColor: '#fff7ea',
                  paddingTop: width < 1000 ? '20px' : null,
                  paddingBottom: width < 1000 ? '20px' : null,
                }}
                className="mb-5"
              >
                <Col md={12}>
                  <h4>Subscribe To Our Newsletter </h4>
                </Col>
                <Col md={12}>
                  <p>
                    All the latest happenings in the data realm, delivered to your inbox
                    every week.
                  </p>
                </Col>
                <Col md={12}>
                  <Form>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        // onKeyPress={handleContinue}
                        value={userEmail}
                        onChange={e => handleEmail(e)}
                        type="email"
                        placeholder="Enter email"
                        style={{
                          backgroundColor: 'transparent',
                          border: '1px solid #FDE0D9',
                          textDecoration: 'none',
                        }}
                      />
                      {emailError.status === true && (
                        <p
                          className="text-start"
                          style={{
                            textAlign: 'start',
                            fontSize: '16px',
                            color: '#f66640',
                          }}
                        >
                          {emailError.message}
                        </p>
                      )}

                      {ghostSubscription === true && (
                        <p
                          className="text-start"
                          style={{
                            textAlign: 'start',
                            fontSize: '16px',
                            color: '#f66640',
                            marginTop: '10px',
                          }}
                        >
                          Email has been sent . Please verify to receive newsletters .
                        </p>
                      )}
                      <Row className="justify-content-center mt-3">
                        <Col md={12} className="d-flex justify-content-center">
                          {emailError.status === true || userEmail.length === 0 ? (
                            <Button
                              disabled
                              className="w-100"
                              style={{
                                backgroundColor: '#f66640',
                                border: '1px solid #f66640',
                                color: 'white',
                                height: '40px',
                                borderRadius: '7px',
                              }}
                            >
                              Subscribe Now
                            </Button>
                          ) : (
                            <Button
                              onClick={e => handleSubscribeToNewsletter(e)}
                              className="w-100"
                              style={{
                                backgroundColor: '#f66640',
                                border: '1px solid #f66640',
                                color: 'white',
                                height: '40px',
                                borderRadius: '7px',
                              }}
                            >
                              {subscribeButtonLoading === true ? (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              ) : (
                                '  Subscribe Now!'
                              )}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {/* end here */}
    </>
  );
};
export default Home;

export const getServerSideProps = async context => {
  const categories = (
    await callBackendApi({
      method: 'get',
      url: '/categories',
      params: {limit: 4, sort: {rating: -1}},
    })
  ).data.data;

  const companies = (
    await callBackendApi({
      method: 'get',
      url: '/vendors',
      params: {
        limit: 5,
        sort: {countLikes: -1},
        select: 'companyName companyLogo slug url shortDescription countLikes',
        includeCategories: true,
        categoriesFields: 'name slug',
      },
    })
  ).data.data;

  const stacks = (
    await callBackendApi({
      method: 'get',
      url: '/stacks',
      params: {
        limit: 4,
        includeVendors: true,
        vendorsFields: 'companyName slug companyLogo url',
        select: 'companyName slug companyLogo url description verified',
        sort: {createdAt: -1},
        stackQuery: {isVerified: true, isApproved: true},
      },
    })
  ).data.data;

  // const podcast = (
  //   await callBackendApi({
  //     method: 'get',
  //     url: '/podcasts',
  //   })
  // ).data;

  const featuredJournals = await callBackendApi({
    method: 'get',
    url: '/journal',
  }).then(response => {
    if (response.data.success === true && response.data.data.featuredJournals) {
      return response.data.data.featuredJournals;
    } else {
      return [];
    }
  });

  let rocketShips = [];
  let juryList = [];

  await callBackendApi({
    method: 'get',
    url: '/rocketShips',
    context: context,
  })
    .then(response => {
      rocketShips = response.data;
    })
    .catch(error => null);

  await callBackendApi({
    method: 'get',
    url: '/awards/voter',
    context: context,
  })
    .then(response => {
      juryList = response.data;
    })
    .catch(error => null);

  return {
    props: {
      categories,
      stacks,
      companies,
      featuredJournals,
      // podcast: podcast[0],
      rocketShips,
      juryList,
    },
  };
};
