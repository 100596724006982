import Head from "next/head";

const HeadComponent = (props) => {
  const metaData = {
    title: props.title
      ? props.title + " | Modern Data Stack"
      : "Modern Data Stack",
  };

  return (
    <Head>
      <title>{metaData.title}</title>
      <meta name="title" content={metaData.title} />
      <meta name="description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={props.url} />
      <meta property="og:title" content={metaData.title} />
      <meta property="og:description" content={props.description} />
      <meta
        property="og:image"
        content={props.CustomImage ? props.CustomImageUrl :  "https://moderndatastack.xyz/meta.jpg"}
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={props.url} />
      <meta property="twitter:title" content={metaData.title} />
      <meta property="twitter:description" content={props.description} />
      <meta
        property="twitter:image"
        content={
          props.CustomTwitterImage
            ? props.CustomTwitterImageUrl
            : "https://moderndatastack.xyz/meta.jpg"
        }
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <link rel="shortcut icon" href="/logo.svg" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      {process.env.NEXT_PUBLIC_app_env === "Production" ? (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="IN5QMzzu3UJb4j7zXdm56WuGpBF7MJWC";;analytics.SNIPPET_VERSION="4.15.3";
  analytics.load("IN5QMzzu3UJb4j7zXdm56WuGpBF7MJWC");
  analytics.page();
  }}();
  `,
            }}
          ></script>

          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-VB9JR32TW6"
          ></script>
          <script
            dangerouslySetInnerHTML={{
              __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-VB9JR32TW6');
  `,
            }}
          ></script>
        </>
      ) : (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            !function(){
              window.analytics = {
                track : ()=> null
              };
              
            }();
  `,
            }}
          ></script>
        </>
      )}
    </Head>
  );
};

export default HeadComponent;
