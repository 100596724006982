import { Container, Carousel, Row, Col, Button } from "react-bootstrap";
import styles from "../../styles/Home.module.css";
import { useState, useLayoutEffect, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Link from "next/link";
import ImageCompanyLogo from "../ImageCompanyLogo";

const TopCompanies = (props) => {
  const {company, index} = props;
  const [mouseEnter, setMouseEnter] = useState(false);
  const renderCompanyName = (item) => <Tooltip>{item}</Tooltip>;;
  const trackClick = (event) => {
    window.analytics.track(`homepage_top_companies_clicked` , event)
  };
  return (
    <div
      className="mds-shadow-hover border mds-border-primary-light mds-rounded-lg mb-3" 
      style={{
        padding: "12px",
        // backgroundColor: mouseEnter === true  ?  "red" : "blue"
      }}

    >
      <Link href="/company/[id]" as={`/company/${company.slug}`}>
        <a
        id={"topVendorLink" + index}
        className={styles.card} onClick={() => trackClick(company)}
>

          <Row>
            <Col md={3}>
              <div
                style={{
                  backgroundColor: "#FEEBDC",
                  minHeight: "100%",
                  borderRadius: "7px",
                }}
              >
                <Row className="justify-content-center">
                  <Col
                    md={10}
                    className="d-flex justify-content-center my-auto"
                    style={{ padding:"25px"}}
                  >
                   <ImageCompanyLogo
                   company={company}
                   width={80}
                   height={80}
                   className="rounded-circle"
                   />
                  </Col>
                </Row>
              </div>
            </Col>

            {/* style={{position:"relative"}} */}
            
            <Col md={9} style={{position:"relative"}}>
              <Row >
              <Col md={10} xs={10} className="d-flex justify-content-start" style={{marginTop:props.width > 800 ? null :"10px"}}>
              <h5 className="text-capitalize">{company.companyName}</h5>
                  </Col>
                
                  {mouseEnter === true && (
                  <Col
                    md={2}
                    xs={2}
                    className="d-flex justify-content-end"
                    style={{ paddingRight: "17px", paddingTop: "5px" }}
                  >
                    <a href={`${company.url}`}>
                      <img
                        width="20"
                        height="20"
                        src="/Illustrations/Redirect Company Url.svg"
                        alt=""
                      />
                    </a>
                  </Col>
                )}
                <Col md={10} xs={10} className={props.width > 800 ? "mt-0" : "mt-2"}>
                  {company.shortDescription.length > 75 ? (
                    <p className="text-muted p-0 m-0">
                      {company.shortDescription.slice(0, 75)}...
                    </p>
                  ) : (
                    <p className="text-muted p-0 m-0">
                      {company.shortDescription}
                    </p>
                  )}
                </Col>
              </Row>
              <Row style={{ marginTop: "18px"  }} >
                <Col md={10} xs={9} style={{ paddingLeft: props.width > 800 ? "15px" : "25px" }}>
                  <Row>
                   <Col
            style={{
              textAlign: "center",
              backgroundColor: "#fff7ea",
              border: "1px solid #FDE0D9",
              borderRadius: "12px",
              minHeight: "34px",
              maxHeight: "34px",
            }}
            className="col-auto d-flex justify-content-center"
       
          > 
            {company.categories && company.categories[0].name.length > 35 ? (
              <OverlayTrigger
                placement="top"
                overlay={renderCategoryName(company.categories[0].name)}
              >
                <Link
                  href="/category/[id]"
                  as={`/category/${
                    company.categories &&
                    company.categories[0] &&
                    company.categories[0].slug
                  }`}
                >
                  <a className={styles.card}>
                    <p className="m-0 p-1" style={{ color: "#f66640" }}>
                      {company.categories[0].name.slice(0, 35)}...
                    </p>
                  </a>
                </Link>
              </OverlayTrigger>
            ) : (
              <Link
                href="/category/[id]"
                as={`/category/${
                  company.categories &&
                  company.categories[0] &&
                  company.categories[0].slug
                }`}
              >
                <a className={styles.card}>
                  <p className=" p-1" style={{ color: "#f66640" , fontSize:"14px" }}>
                    {company.categories && company.categories[0]?.name}
                  </p>
                </a>
              </Link>
            )}
          </Col>
                  </Row>
                </Col>
                <Col
                  md={2}
                  xs={3}
                  className="d-flex justify-content-end"
                  style={{ paddingRight: "15px", paddingTop: "5px" }}
                >
                  <div
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #FDE0D9",
                      borderRadius: "5px",
                      maxWidth: "60px",
                      minWidth: "60px",
                      minHeight: "30px",
                      maxHeight: "30px",
                      cursor: "pointer",
                    }}
                  >
                    <Row className="">
                      <Col
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        style={{ marginLeft: "8px", marginTop: "2px" }}
                      >
                        <img
                          className="m-0 mb-1"
                          src="/045-heart.svg"
                          width="15px"
                          height="15px"
                        />
                      </Col>

                      <Col
                        xl={3}
                        lg={3}
                        md={3}
                        sm={3}
                        xs={3}
                        style={{
                          color: "#f66640",
                          marginLeft: company.countLikes > 9 ? "1px" : "7px",
                          marginTop: "2px",
                          fontSize:"14px"
                        }}
                      >
                        {company.countLikes}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

        </a>
      </Link>
    </div>
  );
};

export default TopCompanies;
